import { PLATFORM, autoinject } from 'aurelia-framework';
import { PubSub } from 'lib/event/PubSub';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { RouterConfiguration } from 'aurelia-router';
import { ServiceInvoiceService } from 'services/service-invoice-service';
import { ServiceInvoiceCounts } from 'models';
import { AsyncErrorHandler } from 'lib/ui';

@autoinject
export class ServiceInvoicingList {
  private exportFor: 'service-invoiceable' | 'service-invoiced' = 'service-invoiceable';

  protected subscriptions: Array<Subscription> = [];
  protected counts: ServiceInvoiceCounts;

  constructor(
    protected pubsub: PubSub,
    protected eventAggregator: EventAggregator,
    private serviceInvoiceService: ServiceInvoiceService
  ) {
    const subscription = this.eventAggregator.subscribe('changeTab', (index: number) => {
      this.exportFor = index === 0 ? 'service-invoiceable' : 'service-invoiced';
      this.eventAggregator.publish('changeTab-success', index);
    });

    this.pubsub.sub('tabs-meta:update', () => {
      void this.getServiceInvoiceCounts();
    });

    this.subscriptions.push(subscription);
  }

  private setTabMeta(counts: ServiceInvoiceCounts) {
    const data = [
      {
        id: 'service-invoicing-invoice',
        meta: `(${counts.AvailableToInvoice})`,
      },
      {
        id: 'service-invoice-inprogress',
        meta: `(${counts.InProgress})`,
      },
      {
        id: 'service-invoice-invoiceable',
        meta: `(${counts.Approved})`,
      },
      {
        id: 'service-invoicing-invoiced',
        meta: `(${counts.Invoiced})`,
      },
    ];

    this.pubsub.publish('tabs-meta:changed', data);
  }

  protected async attached() {
    await this.getServiceInvoiceCounts();
  }

  @AsyncErrorHandler
  protected async getServiceInvoiceCounts() {
    this.counts = await this.serviceInvoiceService.counts();
    this.setTabMeta(this.counts);
  }

  protected detatched() {
    this.subscriptions.forEach((subscription) => {
      subscription.dispose();
    });

    this.pubsub.unsub();
  }

  protected export() {
    this.pubsub.publish('export-list', this.exportFor);
  }

  protected configureRouter(config: RouterConfiguration) {
    return config.map([
      {
        route: [''],
        name: 'service-invoicing-invoice',
        moduleId: PLATFORM.moduleName('./service-invoiceable'),
        tabindex: 0,
        nav: true,
        entityShortName: 'invoice',
      },
      {
        route: ['inprogress'],
        name: 'service-invoice-inprogress',
        moduleId: PLATFORM.moduleName('./service-invoice-inprogress'),
        tabindex: 1,
        nav: true,
        entityShortName: 'inprogress',
      },
      {
        route: ['invoiceable'],
        name: 'service-invoice-invoiceable',
        moduleId: PLATFORM.moduleName('./service-invoice-invoiceable'),
        tabindex: 2,
        nav: true,
        entityShortName: 'invoiceable',
      },
      {
        route: ['invoiced'],
        name: 'service-invoicing-invoiced',
        moduleId: PLATFORM.moduleName('./service-invoiced'),
        tabindex: 3,
        nav: true,
        entityShortName: 'invoiced',
      },
    ]);
  }
}
