export const getAllTasks = () => {
  return [
    {
      invoiceDate: 'InvoicedWashing',
      invoiceable: 'InvoiceWashing',
      completedDate: 'WashedDate',
      canSetCompleteDate: true,
      label: 'service.tasks.washing',
      orderedDate: 'OrderedWashing',
      serviceStationId: 'WashingServiceStationId',
      serviceStationName: 'WashingServiceStationName',
      index: 1,
    },
    {
      invoiceDate: null,
      invoiceable: null,
      completedDate: 'MeasuredDate',
      canSetCompleteDate: true,
      label: 'service.tasks.measuring',
      orderedDate: 'OrderedMeasuring',
      serviceStationId: 'MeasuringServiceStationId',
      serviceStationName: 'MeasuringServiceStationName',
      index: 2,
    },
    {
      invoiceDate: 'InvoicedTesting',
      invoiceable: 'InvoiceTesting',
      completedDate: 'TestedDate',
      canSetCompleteDate: true,
      label: 'service.tasks.testing',
      orderedDate: 'OrderedTesting',
      serviceStationId: 'TestingServiceStationId',
      serviceStationName: 'TestingServiceStationName',
      index: 3,
    },
    {
      invoiceDate: 'InvoicedRepair',
      invoiceable: 'InvoiceRepair',
      completedDate: 'RepairedDate',
      canSetCompleteDate: true,
      label: 'service.tasks.repairing',
      orderedDate: 'OrderedRepair',
      serviceStationId: 'RepairServiceStationId',
      serviceStationName: 'RepairServiceStationName',
      index: 4,
    },
    {
      invoiceDate: 'InvoicedAntifouling',
      invoiceable: 'InvoiceAntifouling',
      completedDate: 'AntifouledDate',
      canSetCompleteDate: true,
      label: 'service.tasks.antifouling',
      orderedDate: 'OrderedAntifouling',
      serviceStationId: 'AntifoulingServiceStationId',
      serviceStationName: 'AntifoulingServiceStationName',
      index: 5,
    },
    {
      invoiceDate: 'InvoicedSaghetti',
      invoiceable: 'InvoiceSpaghetti',
      completedDate: 'SpaghettiDate',
      canSetCompleteDate: true,
      label: 'service.tasks.spaghetti',
      orderedDate: 'OrderedSpaghetti',
      serviceStationId: 'SpaghettiServiceStationId',
      serviceStationName: 'SpaghettiServiceStationName',
      index: 7,
    },
    {
      invoiceDate: 'InvoicedDelivery',
      invoiceable: 'InvoiceDelivery',
      completedDate: 'DeliveredDate',
      canSetCompleteDate: false,
      label: 'service.tasks.delivery',
      orderedDate: 'OrderedDelivery',
      serviceStationId: 'DeliveryServiceStationId',
      serviceStationName: 'DeliveryServiceStationName',
      index: 6,
    },
  ];
};
