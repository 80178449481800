export const getInvoicingFields = () => {
  return {
    InvoicedWashingStatus: {
      OrderedField: 'InvoicedWashingStatusOrderedWashing' /* boolean */,
      InvoiceField: 'InvoicedWashingStatusInvoiceWashing' /* boolean */,
      StatusDate: 'InvoicedWashingStatusWashedDate' /* date */,
      InvoicedField: 'InvoicedWashingStatusInvoicedWashing' /* date */,
      QuickEditFieldUpdate: 'InvoicedWashing' /* date */,
    },
    InvoicedTestingStatus: {
      OrderedField: 'InvoicedTestingStatusOrderedTesting',
      InvoiceField: 'InvoicedTestingStatusInvoiceTesting',
      StatusDate: 'InvoicedTestingStatusTestedDate',
      InvoicedField: 'InvoicedTestingStatusInvoicedTesting',
      QuickEditFieldUpdate: 'InvoicedTesting',
    },
    InvoicedRepairStatus: {
      OrderedField: 'InvoicedRepairStatusOrderedRepair',
      InvoiceField: 'InvoicedRepairStatusInvoiceRepair',
      StatusDate: 'InvoicedRepairStatusRepairedDate',
      InvoicedField: 'InvoicedRepairStatusInvoicedRepair',
      QuickEditFieldUpdate: 'InvoicedRepair',
    },
    InvoicedAntifoulingStatus: {
      OrderedField: 'InvoicedAntifoulingStatusOrderedAntifouling',
      InvoiceField: 'InvoicedAntifoulingStatusInvoiceAntifouling',
      StatusDate: 'InvoicedAntifoulingStatusAntifouledDate',
      InvoicedField: 'InvoicedAntifoulingStatusInvoicedAntifouling',
      QuickEditFieldUpdate: 'InvoicedAntifouling',
    },
    InvoicedSpaghettiStatus: {
      OrderedField: 'InvoicedSpaghettiStatusOrderedSpaghetti',
      InvoiceField: 'InvoicedSpaghettiStatusInvoiceSpaghetti',
      StatusDate: 'InvoicedSpaghettiStatusSpaghettiDate',
      InvoicedField: 'InvoicedSpaghettiStatusInvoicedSpaghetti',
      QuickEditFieldUpdate: 'InvoicedSpaghetti',
    },
    InvoicedDeliveryStatus: {
      OrderedField: 'InvoicedDeliveryStatusOrderedDelivery',
      InvoiceField: 'InvoicedDeliveryStatusInvoiceDelivery',
      StatusDate: 'InvoicedDeliveryStatusDeliveredDate',
      InvoicedField: 'InvoicedDeliveryStatusInvoicedDelivery',
      QuickEditFieldUpdate: 'InvoicedDelivery',
    },
  };
};
